
.nav {
  @apply relative flex flex-row justify-between w-full;
  height: var(---height)
}

.navMenu {
  @apply hidden ml-8 space-x-4 lg:block;
  height: var(---height)
}

.link {
  @apply inline-flex items-center leading-6
  transition ease-in-out duration-75
  text-accent-5;
}

.link:hover {
  @apply text-accent-9;
}

.link:focus {
  @apply outline-none text-accent-8;
}
