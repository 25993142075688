.root {
  @apply w-full min-w-full relative flex flex-row items-center overflow-hidden py-0;
  max-height: 320px;
}

.root > div {
  max-height: 320px;
  padding: 0;
  margin: 0;

  & > *{
    &:nth-child(1) {
      @apply bg-violet;
    }
    &:nth-child(2) {
      @apply bg-pink;
    }
    &:nth-child(3) {
      @apply bg-blue;
    }
    &:nth-child(4) {
      @apply bg-green;
    }
    &:nth-child(5) {
      @apply bg-pink-light;
    }
    &:nth-child(6) {
      @apply bg-cyan;
    }
  }

}

.root > div > * > *:nth-child(2) * {
  max-height: 100%;
}

.primary {
  @apply bg-accent-0;
}

.secondary {
  @apply bg-accent-9;
}
