.root {
  min-height: 100vh;
}

.root.empty {
  @apply bg-secondary text-secondary;
}

.lineItemsList {
  @apply py-4 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-accent-2 border-accent-2;
}
