.root {
  @apply flex flex-col py-4;
}

.root:first-child {
  padding-top: 0;
}

.quantity {
  appearance: textfield;
  @apply w-8 border-accent-2 border mx-3 rounded text-center text-sm text-black;
}

.quantity::-webkit-outer-spin-button,
.quantity::-webkit-inner-spin-button {
  @apply appearance-none m-0;
}

.productImage {
  position: absolute;
  transform: scale(1.9);
  width: 100%;
  height: 100%;
  left: 30% !important;
  top: 30% !important;
  z-index: 1;
}

.productName {
  @apply font-medium cursor-pointer pb-1;
  margin-top: -4px;
}
